import React from 'react';
import './LoadHobbyzhub.css';

const Loadhobbyzhub = () => {
  return (
    <div className='load-hobbyzhub-container absolute-center'>
      <div className="load-hobbyzhub-image-wrapper">
        <img src="/assets/hobbyzhub-grey-logo.png" alt="hobbyzhub logo" />
      </div>
    </div>
  )
}

export default Loadhobbyzhub