import { lazy, Suspense } from "react";
import { BrowserRouter,Routes,Route} from "react-router-dom";
import Loadhobbyzhub from "./Components/loading/LoadHobbyzhub";


const Signup = lazy(()=> import ('./Pages/signup/Signup'));
const Registration = lazy(()=> import ('./Pages/registration/Registration'));
const Categories = lazy(()=> import ('./Pages/categories/Categories'));
const Subcategories = lazy(()=> import ('./Pages/subcategories/Subcategories'));
const Login = lazy(()=> import ('./Pages/login/Login'));
const Home = lazy(()=> import ('./Pages/home/Home'));
const Otp = lazy(()=> import ('./Pages/otp/Otp'));
const Profile = lazy(()=> import ('./Pages/profile/Profile'));
const Account = lazy(()=> import ('./Pages/account/Account'));
const Security = lazy(()=> import ('./Pages/setting/security/Security'));
const PrivacyPolicies = lazy(()=> import ('./Pages/setting/privacyPolicies/PrivacyPolicies'));
const HelpCenter = lazy(()=> import ('./Pages/setting/helpCenter/HelpCenter'));
const AccountSetting = lazy(()=> import ('./Pages/setting/accountSetting/AccountSetting'));
const Group = lazy(()=> import ('./Pages/group/Group'));
 

function App() {

  return (
    <>
    <BrowserRouter>
    <Suspense fallback={<Loadhobbyzhub/> }>
    <Routes>
      <Route path='/home' element={<Home/>}/>
      <Route path='/' element={<Signup/>}/>
      <Route path="/registration" element={<Registration />}/>
      <Route path='/categories' element={<Categories/>}/>
      <Route path='/subcategories' element={<Subcategories/>}/>
      <Route path='/login' element={<Login/>}/>
      <Route path ="/otp" element={<Otp />}/>
      <Route path='/profile' element={<Profile/>}/>
      <Route path='/account' element={<Account/>}/>
      <Route path='/setting/security' element={<Security/>}/>
      <Route path='/setting/privacyPolicies' element={<PrivacyPolicies/>}/>
      <Route path='/setting/helpCenter' element={<HelpCenter/>}/>
      <Route path='/setting/accountSetting' element={<AccountSetting/>}/>
      <Route path='/group' element={<Group/>}/>
    </Routes>
    </Suspense>
    </BrowserRouter>
    </>
  );
}

export default App;
